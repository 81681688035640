const appConfig = {
  // apiPrefix: "https://api-erp.brothers.net.in/api/",
  // apiPrefix: "https://3b54-103-16-30-68.ngrok-free.app/api/",
  // apiPrefix: "http://localhost:5005/api/",
  apiPrefix: "https://brothers-backend.onrender.com/api/",
  // apiPrefix: "https://brothers-server-1.onrender.com/api/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: true,
};

export default appConfig;
